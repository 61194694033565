/*!
* This is the login page stylesheet.
* @author  Bharat Gogineni <bharat@vantageagora.com>
*/
$primary: rgb(182, 157, 230);
html {
  height: 100%;
  overflow: hidden;
}
body {
    height: 100%;
    margin: 0;
}
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,900');
body.login{
    // background-image: url('../images/wallpaper.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.57;
    color: #545456;
    background-color: transparent;
}
img#ox-logo {
    width: 100%;
    height: auto;
}
.reactLoginPage{
  height: 100%;
}
.loginContainer {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  height: 100%;
    margin: 0 !important;
}
#login-container {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ox-forgotPWText{
  margin: 20px 0px;
}
#ox-container {
  height: 100%;
}
img#AnnouncementIcon {
    height: 30px;
}

#ox-login-form {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  flex-flow: column;
  flex-grow: 3;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  min-width: 300px;
  h4 {
    margin-bottom: 20px;
    color: rgba(#000, 0.5);
    span {
      color: rgba(#000, 1);
      font-weight: 700;
    }
  }
  p {
    line-height: 155%;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    opacity: 0.65;
    font-weight: 400;
    max-width: 200px;
    margin-bottom: 40px;
  }
  a.discrete {
    color: rgba(#000, 0.4);
    font-size: 14px;
    border-bottom: solid 1px rgba(#000, 0);
    padding-bottom: 4px;
    margin-left: auto;
    font-weight: 300;
    transition: all 0.3s ease;
    margin-top: 40px;
    &:hover {
      border-bottom: solid 1px rgba(#000, 0.2);
    }
  }
button {
  -webkit-appearance:  none; 
  width: 100%;
  max-width: 250px;
  cursor:pointer;
  min-width:  100px;
  border-radius:  .25em; 
  text-align:  center; 
  padding:  15px 40px;
  margin-top:  10px;
  margin-bottom:  10px;
  background-color: #275362; 
  color:  #fff; 
  font-size:  14px;
  font-weight:  500; 
  box-shadow:  0px 2px 6px -1px rgba(0,0,0,.13); 
  border:  none;
  transition:  all .3s ease; 
  outline: 0; 
  &:hover {
    transform:  translateY(-3px);
    background-color:#275362;
    box-shadow:  0 2px 6px -1px rgba($primary, .65);
    &:active {
      transform:  scale(.99);
    }
    }
  }
  input {
    font-size: 16px;
    padding: 20px 0px;
    height: 56px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    width: 280px;
    box-sizing: border-box;
    transition: all 0.3s linear;
    background: transparent;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus {
      border-bottom: solid 1px #275362;
      outline: 0;
      box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
    }
  }

  .floating-label {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    label {
      top: calc(50% - 7px);
      left: 0;
      opacity: 0;
      transition: all 0.3s ease;
    }
    input {
      width: 100%;
      display: flex;
    }
    input:not(:placeholder-shown) {
      padding: 28px 0px 12px 0px;
      opacity: 1;
    }
    input:not(:placeholder-shown) + label {
      transform: translateY(-20px);
    }
    input:not(:valid):not(:focus) + label + .icon {
      animation-name: shake-shake;
      animation-duration: 0.3s;
    }
  }
}
$displacement: 3px;
@keyframes shake-shake {
  0% {
    transform: translateX(-$displacement);
  }
  20% {
    transform: translateX($displacement);
  }
  40% {
    transform: translateX(-$displacement);
  }
  60% {
    transform: translateX($displacement);
  }
  80% {
    transform: translateX(-$displacement);
  }
  100% {
    transform: translateX(0px);
  }
}
.form-wrapper {
  max-width: 515px;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px 0;
  display: flex;
}
.form-wrapper__inner {
  align-items: center;
  padding: 0px;
}
.loginSlider{
    padding: 0 !important;
    height: inherit
}
.form-signin__footer {
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.form-signin {
  width: 100%;
}
.footer-links {
   margin-bottom: 25px;
   text-align: center;
   position: absolute;
   left:0px;
   bottom:0px;
   width:100%;
}

.ox-form {
  margin: 0;
}

.ox-imgDiv {
  text-align: center;
  margin-bottom: 30px;
}

.ox-content {
  width: 100%;
  padding: -5 px;
}

.form-filler {
  width: 100;
  height: 120px;
}

.footer-CR {
  color: white;
}
.login .input-field > label {
  user-select: none;
}

.login-copyright {
  font-size: 12px;
  color: #b3b3b3;
  text-align: center;
  padding: 0 16px;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  z-index: 10;
  background-color: #452767;
}

.coppyRight{
  color: #275362;
}

.osjs-login-error {
  //background: #fae1dc;
  color: #ff2b00;
  //border-color: #fae1dc;
  min-height: 24px;
  font-size: 13px;
  line-height: 1.23;
  text-shadow: none;
  position: relative;
  padding: 4px;
  margin-bottom: 1rem;
  //border: 1px solid transparent;
  //border-radius: 2px;
}

@media screen and (max-width: 900px) {
  #ox-login-form {
    text-align: center;
    position: relative;
    margin: 0px;
    overflow: auto;
    flex: 0 0 100%;
    max-width: 500px;
  }
  .form-wrapper__inner {
    padding: 30px;
  }
  .ox-imgDiv {
    margin-bottom: 30px;
  }
  .loginSlider{
    display: none!important;
    // width: 100% !important;
    // flex: 0 0 100% !important;
    // max-width: 100% !important;
  }
  .loginContainer{
    // flex-wrap: wrap-reverse !important;
    flex-direction: column;
    align-items: center;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 350px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 5px 5px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  right: 10%;
  top: -100%;
}
.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}

.resetPasswordInputField {
  display: inline-flex;

  .newPasswordDiv {
    display: inline;
    width: 100%;
  }

  .infoDiv {
    display: inline;
    width: 10%;
    margin: auto;
    border-bottom: none;
    position: relative;
  }
}

.passwordRestInfoIcon {
  color: white;
  font-size: 1.5em;
  border-radius: 10px;
  background-color: #275362;
}
a.footer-link{
  color: #275362;
    text-decoration: dashed;
}
a.forgotpasswordLink{
margin:15px;
  color: #275362;
  border-bottom: 1px dotted #275362;
    text-decoration: none;
}
.footer{
  position: absolute;
  display: flex;
  bottom: 30px;
  width: 100%;
  flex-direction: column;
}
.Announcement-visuals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

    background-color: #edf3f2;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%!important;
    position: absolute !important;
    top: 0px;
    align-content: center;
  }

  .fallbackImage {
    height: 45%;
    width: 100%;
  }

  .fallbackText {
    background-color: #3c3a3abf;
    height: 15vh;
  }

  .Announcement-visuals {
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .Announcement-content {
    position: absolute !important;
    width: 100% !important;
    text-align: center;
    bottom: 0px;
    height: auto;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
  }

  h5,
  h6 {
    white-space: normal;
    font-weight: bolder;
  }
  .reactLoginPage{
    height: 100%;
  }
  .announcement-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .slide {
    position: unset;
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .arrowWrap {
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    position: absolute;

    .arrow {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      transition: transform ease-in 0.1s;
      opacity: 0.3;
    }

    div.arrow:hover {
      opacity: 1;
      background-color: white;
    }

    .backArrow {
      z-index: 100;
      color: gray;
      opacity: 0.3;
      background-color: white;
      margin-left: 1%;
    }

    .nextArrow {
      z-index: 100;
      color: gray;
      opacity: 0.3;
      background-color: white;
      margin-right: 1%;
    }
  }

  .AncVideo {
    max-width: 100%;
    height: 85%;
  }

  .cloud-sliding-panel-container {
    overflow: hidden;
    background-color: #0000004d;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    flex-direction: column;
    .bottomPanelShow {
      width: 87%;
      height: 81%;
      overflow-y: auto;
    }
    .panel-glass {
      height: inherit;
      width: inherit;
    }
    .popup-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      h6 {
        font-size: 1.5rem;
        margin-bottom: 3vh;
        margin-top: 4vh;
        max-width: 70%;
        user-select: text;
      }

      .mainText {
        user-select: text;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased !important;
        -moz-font-smoothing: antialiased !important;
        text-rendering: optimizelegibility !important;
        letter-spacing: 0.03em;
        max-width: 80%;
        font-family: "Open Sans", sans-serif;
        justify-content: center;
        font-size: 16px;
        text-align: center;
        line-height: 135%;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        white-space: normal;
        word-break: break-word;
      }

      .buttonWrap {
        margin: 3%;
        width: 15rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .popupButtons {
        margin-left: 6%;
        margin-right: 6%;
      }
    }
  }

  .actionButton {
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 9px 15px;
    background-color: #275362;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 2px 2px 6px 1px #000000b5;
    border: none;
    outline: 0;
    &:active {
      transform: scale(0.99);
    }
  }